button.upload-button,
button.submit-button {
  background-color: #5163be;
  width: 150px;
  &:hover {
    background-color: #5163be;
  }
  span {
    font-size: 14px;
    color: white;
    font-weight: bold;
  }
}
button.upload-button {
  margin-top: 15px;
  margin-bottom: 15px;
}
button.submit-button {
  margin: 15px auto;
}

.upload-file-instructions {
  width: 500px;
  background-color: #142ba1;
  padding: 10px;
  margin: 0 0 20px 0;
}
.upload-file-area {
  background-color: grey;
  width: 300px;
  padding: 10px;
  margin: 15px 0;
}

#report-modal,
#form-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
#report-modal .report-modal-body,
#form-modal .form-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  background-color: white;
  padding: 15px;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
  .error-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  button {
    margin-top: 10px;
    background-color: #5163be;
    span {
      color: white;
    }
  }
  button.no-btn {
    background-color: grey;
    margin-left: 10px;
  }
}
#guest-list-form {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  .form-grid {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-root {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding-inline-start: 5px;
  }
  label + .MuiInput-formControl,
  .MuiFormControl-marginNormal {
    margin-top: 8px;
  }
  .MuiFormControl-marginNormal {
    margin-bottom: unset;
  }
  .MuiFormLabel-root {
    color: #000;
    font-size: 14px;
    line-height: 18px;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
  }
  .MuiFormLabel-root.Mui-focused {
    color: #5163be;
  }
}

@media (max-width: 600px) {
  .upload-file-instructions,
  .upload-file-area {
    width: 100%;
  }
}
