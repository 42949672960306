.guest-list {
  font-family: "Helvetica" !important;

  .filter-region {
    width: 100%;
  }

  h5 {
    float: left;
    margin: 20px 30px;
    font-size: 18px;
  }

  .MuiTextField-root {
    width: 80%;
  }

  .MuiCircularProgress-root {
    width: 30px;
    height: 30px;
    margin: 10px 50px;
  }

  .MuiTableContainer-root {
    width: 95%;
    margin: 0 auto;
  }

  .MuiTable-root {
    width: 200%;
  }

  .MuiTableCell-head {
    font-weight: bold;
    font-family: "Pyidaungsu" !important;
    background-color: #dddbdd;
  }

  .MuiTableCell-root {
    font-size: 11px;
  }

  .date-filter-text {
    margin-top: 27px;
    margin-right: 20px;
    font-weight: bold;
    display: inline-block;
  }

  .state-placeholder {
    font-size: 15px;
  }

  .MuiInputBase-input {
    font-size: 15px;
  }

  .no-items {
    margin: 27px 20px 0 20px;
    font-weight: bold;
    display: inline-block;
  }

  .filter-btn {
    padding: 10px 20px;
    margin: 15px 5px;
  }

  .state-filter {
    .MuiInputBase-root {
      width: 90%;
      margin-top: 14px;
    }
    .MuiSelect-select {
      font-size: 12px;
    }
  }

  ul.common-pagination {
    float: right;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;

    li.active {
      a {
        color: #000;
      }
    }
    li.disabled {
      a {
        color: #73818f;
        pointer-events: none;
        cursor: auto;
      }
    }
    li {
      a {
        position: relative;
        display: block;
        padding: 7px;
        margin-left: -1px;
        line-height: 1.25;
        color: red;
        cursor: pointer;
      }
    }
  }

  .common-custom-perpage {
    width: 70px !important;
    text-align: center;
    float: left;
    margin-left: 20px;
  }

  .paginate-select {
    background: #5163be !important;
    border-radius: 4px !important;
    color: #e4e7ea !important;
    border: none !important;
    margin: 20px 35px 0 5px;
    padding: 5px;
    text-align: right;
  }
}

.progress {
  width: 100%;
  text-align: center;
}
