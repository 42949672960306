.MuiGrid-item.login-container {
  margin: 0 auto;
  padding: 25px 0 0 0;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item.login-container {
  padding: 25px 12px 0 12px;
}

.login-bg {
  background-image: url("../images/background.jpg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-origin: border-box;
  min-height: 100vh;
  min-width: 100%;

  .MuiGrid-spacing-xs-3 {
    margin: 0px;
  }
}

@media (max-width: 375px) {
  .login-bg {
    .MuiGrid-spacing-xs-3 {
      margin: -12px;
    }
  }
}

.login-wrapper {
  background-color: #fff;
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);

  .title {
    background-color: #795548;
    padding: 16px 16px;
    display: flex;
    justify-content: stretch;
    line-height: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective-origin: 165px 56px;
    perspective-origin: 165px 56px;
    -webkit-transform-origin: 165px 56px;
    transform-origin: 165px 56px;
    box-sizing: border-box;

    img {
      width: 100px;
    }

    span {
      text-align: left;
      margin-left: 14px;
      font-size: 23px;
      line-height: 1.4em;
      color: #fff;
      font-weight: 500;
      font-family: "Helvetica" !important;
    }
  }

  .hotel-title {
    background-color: #3f51b5;
  }

  .login-form {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    line-height: 18px;
    overflow: hidden;
    padding: 16px 16px;
    margin-bottom: 20px;

    h5 {
      font-family: "Helvetica", "Arial", sans-serif;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.02em;
      margin: 5px 0 30px 0;
      font-weight: bold;
      text-align: center;
    }

    .MuiTextField-root {
      width: 100%;

      .MuiInput-underline:before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    p {
      font-size: 14px;
      margin-top: 20px;
      display: inline-block;
    }

    .hotel-input {
      margin: 20px 0 0 0;
    }
  }

  .button-div {
    font-size: 16px;
    line-height: normal;
    width: 100%;
    background-color: transparent;
    padding: 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    button {
      width: 100%;
      color: #3f51b5;
      height: 36px;
      font-weight: bold;
    }

    .MuiCircularProgress-root {
      width: 20px !important;
      height: 20px !important;
      margin-left: 10px;
    }
  }

  .login-footer {
    font-size: 16px;
    line-height: normal;
    width: 100%;
    background-color: transparent;
    padding: 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    p {
      text-align: center;
      a {
        color: #ff4081;
        text-decoration: underline;
        font-size: 14px;
      }
    }

    .phone-no {
      font-size: 13px;
      margin-top: 30px;
    }

    .video-link {
      margin: 2px 0 20px 0;
    }

    .copyright {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
