.mm-font {
  font-family: "Pyidaungsu" !important;
}

#root {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-bottom: 40px;
}

.error-text {
  color: #f00;
  font-size: 13px;
  width: 100%;
  font-weight: 500;
  display: inline-block;
  font-family: "Helvetica";
}

.MuiTextField-root.error-input .MuiInput-underline:before {
  border-bottom: 1px solid #f00 !important;
}

//Main Menu
.menu-container {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #5163be;
}
.main-menu-link {
  text-decoration: none;
}

.top-menu.main-menu-link {
  color: #fff;
  padding: 0 50px;
}
.hotel-menu .top-menu.main-menu-link {
  padding: 0 40px;
}
.side-main-menu-drawer {
  .MuiDrawer-paper {
    width: 280px;
  }
  .MuiListItem-gutters {
    padding-left: 50px;
    padding-top: 20px;
    padding-right: 20px;
  }
  .main-menu-link,
  .version-text {
    color: grey;
  }
}
.side-main-menu-icon {
  color: #fff;
}
